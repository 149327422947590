export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = await useUserStore();
  const config = useRuntimeConfig();
  const base_api = config.public.apiUri;

  if (!userStore.token) {
    if (userStore.refreshToken) {
      var res = await userStore.apiRefresh({
        token: userStore.refreshToken,
      });
      if (res.error) {
        return await navigateTo("/admin/login");
      }
    } else {
      return await navigateTo("/admin/login");
    }
  } else {
    const { data, error } = await useFetch(`${base_api}/check_token`, {
      key: "checkToken",
      method: "get",
      headers: { Authorization: `Bearer ${userStore.token}` },
    });

    if (error.value) {
      userStore.setToken(null);
      userStore.setUser(null);
      userStore.setRefreshToken(null);
      return await navigateTo("/admin/login");
    }

    if (!userStore.user) {
      return await navigateTo("/admin/login");
    }

    if (userStore.user.role == "agent" || userStore.user.role == "subagent") {
      return navigateTo("/admin/cash");
    } else if (userStore.user.role == "user") {
      return navigateTo("/");
    }
  }
});
